<template>
  <div class="custom">
    <!-- 头部工具栏 -->
    <div class="tool">
      <ul>
        <li>
          <img src="@/assets/images/refreshBtn.png" @click="refresh()" alt />
        </li>
        <li>
          <img src="@/assets/images/retreatBtn.png" @click="back()" alt />
        </li>
      </ul>
    </div>
    <!-- 筛选 -->
    <div class="filtrate">
      <div class="header-from">
        <el-form :inline="true" class="demo-form-inline" size="mini">
          <el-form-item>
            <el-button  type="primary" @click="showAddcustom">增加</el-button>
            <el-button  type="primary" @click="upSortBtn">排序</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
        v-loading="loading"
      >
        <el-table-column
          type="selection"
          width="80"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="字段名称"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="key"
          label="字段ID"
          width="180"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="type"
          label="字段类型"
          width="160"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.type | type }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="报告显示状态"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <span :class="scope.row.status == 0 ? 'off' : 'no'">{{
              scope.row.status | statusFilter
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="export_state"
          label="允许导出"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <span :class="scope.row.export_state == 0 ? 'off' : 'no'">{{
              scope.row.export_state | statusFilter
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="search_state"
          label="搜索"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <span :class="scope.row.search_state == 0 ? 'off' : 'no'">{{
              scope.row.search_state | statusFilter
            }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="sort" label="排序" width="120" align="center">
          <template slot-scope="scope">
            <!-- @blur="updateSort(scope.row)" -->
            <el-input
              v-model="scope.row.sort"
              size="mini"
              maxlength="2"
              onkeyup="value=value.replace(/[^\d]/g,'') "
              @blur="updateSort(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="showAddcustom(scope.row)"
              >编辑</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" v-if="scope.row.omit == 0"
              >不可删除</el-button
            >
            <el-button
              size="mini"
              type="plain"
              @click="del(scope.row.key)"
              v-if="scope.row.omit == 1"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加字段模态框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="addCustom"
      width="30%"
      center
      @close="formReset"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        size="mini"
      >
        <el-form-item label="字段名称:" prop="name">
          <el-input v-model="form.name"></el-input>
          <span>例如:"职业"</span>
        </el-form-item>
        <el-form-item label="数据库字段ID:" prop="key">
          <el-input
            v-model="form.key"
            :disabled="form.omit == 1 ? false : true"
          ></el-input>
          <span>注意: 必须为字母，长度为2个字母以上。例如:"name"</span>
        </el-form-item>
        <el-form-item label="字段类型:" prop="type">
          <el-select v-model="form.type">
            <el-option :value="1" label="单行文本框"></el-option>
            <el-option :value="2" label="多行文本框"></el-option>
            <el-option :value="3" label="下拉菜单"></el-option>
            <el-option :value="4" label="单选框"></el-option>
            <el-option :value="5" label="复选框"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="内容:"
          prop="content"
          v-if="form.type === 3 || form.type === 4 || form.type === 5"
        >
          <el-input type="textarea" v-model="form.content" :rows="5"></el-input>
          <span>说明：适用于下拉菜单、单选框、复选框，每个值换一行</span>
        </el-form-item>
        <!-- <el-form-item label="是否开启搜索:">
          <el-radio-group v-model="form.search_state">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否允许导出:">
          <el-radio-group v-model="form.export_state">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="是否报告中显示:">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addCustom = false">取 消</el-button>
        <el-button type="primary" @click="addCustomBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  setArchivesField,
  saveaArchivesField,
  deleteArchivesField,
} from "@/api/evaluate.js";
export default {
  // 引入
  inject: ["reload"],

  data() {
    // 检验方法
    let isKeyPercent = /^[a-zA-Z]*$/;
    var isNum = (rule, value, callback) => {
      if (isKeyPercent.test(value)) {
        callback();
      } else {
        callback(new Error("请输入正确的格式"));
      }
    };
    return {
      // ****检验数据输入****
      rules: {
        name: [{ required: true, message: "请输入字段名称", trigger: "blur" }],
        key: [
          { required: true, message: "请输入字段ID", trigger: "blur" },
          {
            min: 2,
            max: 16,
            message: "长度在 2 到 16 个英文字符",
            trigger: "blur",
          },
          { validator: isNum },
        ],
      },
      // ******************************************
      loading: true,
      form: {
        name: "",
        key: "",
        content: "",
        sort: 1,
        type: 1,
        search_state: 1,
        export_state: 1,
        status: 1,
        content: "",
        id: 0,
        omit: 1,
      },
      tableData: [],
      addCustom: false,
      title: "添加自定义字段",
      // 添加、编辑状态
      isAddStu: 1,
      // 页码
      page: 1,
      pagesize: 15,
      order: "desc",
    };
  },
  created() {
    this.loadArchivesData();
  },
  methods: {
    // 刷新
    refresh() {
      this.reload();
    },
    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    },
    handleSelectionChange() {},
    // 打开添加模态框
    showAddcustom(row) {
      console.log(row, "fsfdf");
      this.addCustom = true;
      this.isAddStu = 1;
      this.title = "添加自定义字段";
      if (row.id) {
        this.isAddStu = 0;
        this.form.id = row.id;
        this.title = "编辑自定义字段";
        let {
          name,
          key,
          content,
          sort,
          type,
          search_state,
          export_state,
          status,
          id,
          omit,
        } = row;
        let data = {
          name,
          key,
          content: row.content.join("\n"),
          sort,
          type,
          search_state,
          export_state,
          status,
          id,
          omit,
        };
        this.form = data;
      } else {
        this.form.id = "";
      }
    },
    // 加载数据
    loadArchivesData() {
      let data = {
        page: this.page,
        pagesize: this.pagesize,
        order: this.order,
      };
      setArchivesField(data).then((res) => {
        //console.log(res);
        if (res.code == 400200) {
          this.tableData = res.data;
          this.loading = false;
        }
      });
    },
    // 模态框添加按钮  /  编辑
    addCustomBtn() {
      let isCode = true; //控制是否表单验证通过
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$message({
            showClose: true,
            message: "温馨提醒，还有内容未填写",
            type: "warning",
          });
          isCode = false;
          return;
        }
      });
      //控制是是否结束请求
      if (!isCode) {
        return;
      }
      saveaArchivesField(this.form).then((res) => {
        let msg = "";
        if (this.isAddStu === 1) {
          msg = "恭喜你，添加成功";
        } else {
          msg = "恭喜你，修改成功";
        }
        if (res.code === 400200) {
          this.$message({
            message: msg,
            type: "success",
          });
          this.loadArchivesData();
          this.addCustom = false;
        }
      });
    },
    // 模态框关闭重置
    formReset() {
      this.form.name = "";
      this.form.key = "";
      this.form.content = "";
      this.form.sort = 1;
      this.form.type = 1;
      this.form.search_state = 1;
      this.form.export_state = 1;
      this.form.status = 1;
      this.form.content = "";
      this.form.omit = 1;
    },
    // 排序更新
    updateSort(row) {
      //console.log(row);
      let data = row;
      delete data.create_time;
      delete data.update_time;
      saveaArchivesField(data).then((res) => {
        if (res.code === 400200) {
          this.$message({
            message: "更新成功",
            type: "success",
          });
        }
      });
    },
    // 删除
    del(key) {
      //  提示 是否删除
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteArchivesField({ key }).then((res) => {
            if (res.code === 400200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.loadArchivesData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 排序按钮
    upSortBtn() {
      if (this.order == "") {
        this.order = "desc";
      } else {
        this.order = "";
      }
      this.loadArchivesData();
    },
  },
  filters: {
    statusFilter(val) {
      return val == 1 ? "启用" : "关闭";
    },
    type(val) {
      let arr = ["单行文本框", "多行文本框", "下拉菜单", "单选框", "复选框"];
      return arr[val - 1];
    },
  },
};
</script>

<style lang="less">
.custom {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  // 头部工具
  .tool {
    position: absolute;
    top: -70px;
    right: 0;
    ul {
      li {
        cursor: pointer;
        list-style: none;
        float: left;
        margin-left: 10px;
        img {
          width: 100px;
        }
      }
    }
  }
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: #ffdec6;
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 150px;
        padding-right: 20px;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
      // .el-button--mini {
      //   padding: 5px 10px;
      //   background: transparent;
      //   color: rgb(4, 87, 102);
      //   font-size: 16px;
      //   &:hover {
      //     border: 1px solid rgb(105, 105, 105);
      //   }
      // }
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 600px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  // 模态框
  .el-dialog__wrapper {
    .el-input__inner {
      width: 200px;
    }
  }
}
</style>